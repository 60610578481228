import * as React from "react"

function Logo() {

	return (

    <div className="logo-svg">
        
        
        <svg width="216" height="57" viewBox="0 0 216 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V43.7927H14.7123V0H0ZM31.3223 21.4584C34.6592 19.433 36.9021 15.7654 36.9021 11.6051C36.9021 5.20038 31.7053 0 25.305 0H20.4364V43.7927H26.8914C33.2917 43.7927 38.4884 38.5923 38.4884 32.1876C38.4884 27.3704 35.5345 23.2101 31.3223 21.4584Z" fill="white"/>
        <path d="M66.0958 0H50.0678L63.1418 43.7927H79.2245L66.0958 0ZM89.0542 0H73.0262L86.1002 43.7927H102.183L89.0542 0Z" fill="white"/>
        <path d="M40.5684 43.7935H56.9793L48.2268 15.1641L40.5684 43.7935Z" fill="#DEB018"/>
        <path d="M0 48.6582H103.573L106.354 56.9997H0V48.6582Z" fill="white"/>
        <path d="M123 20.1178V0H136.599C139.236 0 141.273 0.502071 142.71 1.50621C144.148 2.50162 144.866 3.92052 144.866 5.7629C144.866 7.60529 144.09 8.92813 142.538 9.73145C144.637 10.5348 145.687 12.0759 145.687 14.3549C145.687 18.1968 142.931 20.1178 137.419 20.1178H123ZM137.419 12.7177H129.667V14.2763H137.419C138.213 14.2763 138.609 14.0056 138.609 13.4642C138.609 12.9665 138.213 12.7177 137.419 12.7177ZM136.599 5.84149H129.667V7.40009H136.599C137.401 7.40009 137.802 7.15124 137.802 6.65353C137.802 6.11217 137.401 5.84149 136.599 5.84149Z" fill="white"/>
        <path d="M155.793 14.6692H158.095V20.1178H155.793C152.989 20.1178 150.903 19.5808 149.536 18.5068C148.178 17.424 147.499 15.6908 147.499 13.3071V0H153.703V13.3071C153.703 14.2152 154.4 14.6692 155.793 14.6692Z" fill="white"/>
        <path d="M168.4 20.2487H167.725C164.92 20.2487 162.835 19.7117 161.468 18.6378C160.11 17.555 159.431 15.8218 159.431 13.438V2.68499H165.635V13.438C165.635 14.3461 166.331 14.8002 167.725 14.8002H172.685C173.823 14.8002 174.392 14.3461 174.392 13.438V2.68499H180.596V20.1178H174.392V18.2972C173.237 19.5982 171.239 20.2487 168.4 20.2487Z" fill="white"/>
        <path d="M190.888 14.6692H203.111V20.1178H190.888C188.083 20.1178 185.998 19.5808 184.631 18.5068C183.273 17.424 182.593 15.6908 182.593 13.3071V9.49569C182.593 7.11195 183.273 5.38308 184.631 4.30908C185.998 3.22635 188.083 2.68499 190.888 2.68499H197.012C199.296 2.68499 200.99 3.11721 202.092 3.98164C203.203 4.84608 203.759 6.24751 203.759 8.18594C203.759 10.1244 203.203 11.5302 202.092 12.4033C200.99 13.2765 199.296 13.7131 197.012 13.7131H194.327C191.84 13.7131 189.997 13.3158 188.798 12.5212V13.3071C188.798 14.2152 189.494 14.6692 190.888 14.6692ZM197.012 8.13355H189.538C189.044 8.13355 188.798 8.29072 188.798 8.60506C188.798 8.92813 189.044 9.08967 189.538 9.08967H197.012C197.497 9.08967 197.74 8.92813 197.74 8.60506C197.74 8.29072 197.497 8.13355 197.012 8.13355Z" fill="white"/>
        <path d="M129.667 25.8822L136.175 33.4526L142.67 25.8822H149.338V46H142.67V35.1422L136.175 42.6994L129.667 35.1422V46H123V25.8822H129.667Z" fill="white"/>
        <path d="M161.534 46H159.801C156.997 46 154.911 45.463 153.544 44.389C152.186 43.3063 151.507 41.573 151.507 39.1893V35.3779C151.507 32.9942 152.186 31.2653 153.544 30.1913C154.911 29.1086 156.997 28.5672 159.801 28.5672H161.534C163.986 28.5672 165.776 29.1391 166.905 30.283V28.5672H173.109V46H166.905V44.2842C165.776 45.4281 163.986 46 161.534 46ZM159.801 40.5514H165.198C166.336 40.5514 166.905 40.0974 166.905 39.1893V35.3779C166.905 34.4698 166.336 34.0158 165.198 34.0158H159.801C158.408 34.0158 157.711 34.4698 157.711 35.3779V39.1893C157.711 40.0974 158.408 40.5514 159.801 40.5514Z" fill="white"/>
        <path d="M181.694 35.3779V46H175.49V28.5672H181.694V30.5711C182.752 29.2352 184.604 28.5672 187.25 28.5672H189.393V34.0158H183.4C182.263 34.0158 181.694 34.4698 181.694 35.3779Z" fill="white"/>
        <path d="M190.861 25.8822H197.065V36.8317L204.169 28.5672H211.59L204.301 37.0151L212 46H204.566L197.065 37.2509V46H190.861V25.8822Z" fill="white"/>
        <path d="M127.842 51H129.87V57H127.842V54.8711H125.032V57H123V51H125.032V53.1289H127.842V51Z" fill="#DEB018"/>
        <path d="M138.838 51H140.604C141.59 51 142.323 51.1849 142.801 51.5547C143.28 51.9245 143.519 52.5208 143.519 53.3437V54.6563C143.519 55.4792 143.28 56.0755 142.801 56.4453C142.323 56.8151 141.59 57 140.604 57H138.838C137.851 57 137.119 56.8151 136.641 56.4453C136.162 56.0755 135.923 55.4792 135.923 54.6563V53.3437C135.923 52.5208 136.162 51.9245 136.641 51.5547C137.119 51.1849 137.851 51 138.838 51ZM138.838 55.2578H140.604C141.192 55.2578 141.487 55.0573 141.487 54.6563V53.3437C141.487 52.9427 141.192 52.7422 140.604 52.7422H138.838C138.247 52.7422 137.951 52.9427 137.951 53.3437V54.6563C137.951 55.0573 138.247 55.2578 138.838 55.2578Z" fill="#DEB018"/>
        <path d="M149.571 51H151.603V55.2578H155.772V57H149.571V51Z" fill="#DEB018"/>
        <path d="M166.034 57H161.615V51H166.034C167.023 51 167.756 51.1849 168.232 51.5547C168.71 51.9245 168.949 52.5208 168.949 53.3437V54.6563C168.949 55.4792 168.71 56.0755 168.232 56.4453C167.756 56.8151 167.023 57 166.034 57ZM166.034 52.7422H163.647V55.2578H166.034C166.623 55.2578 166.917 55.0573 166.917 54.6563V53.3437C166.917 52.9427 166.623 52.7422 166.034 52.7422Z" fill="#DEB018"/>
        <path d="M174.998 51H177.03V57H174.998V51Z" fill="#DEB018"/>
        <path d="M183.22 51H185.252L188.094 54.2383V51H190.126V57H188.094L185.252 53.7617V57H183.22V51Z" fill="#DEB018"/>
        <path d="M203.4 51V52.7422H199.09C198.498 52.7422 198.203 52.9427 198.203 53.3437V54.6563C198.203 55.0573 198.498 55.2578 199.09 55.2578H201.368V54H203.4V57H199.09C198.103 57 197.371 56.8151 196.892 56.4453C196.414 56.0755 196.175 55.4792 196.175 54.6563V53.3437C196.175 52.5208 196.414 51.9245 196.892 51.5547C197.371 51.1849 198.103 51 199.09 51H203.4Z" fill="#DEB018"/>
        <path d="M211.69 51H215.802V52.7422H211.69C211.459 52.7422 211.343 52.8229 211.343 52.9844C211.343 53.1328 211.459 53.207 211.69 53.207H213.629C214.433 53.207 215.028 53.3581 215.415 53.6602C215.805 53.9596 216 54.4414 216 55.1055C216 55.7695 215.805 56.2513 215.415 56.5508C215.028 56.8503 214.433 57 213.629 57H209.521V55.2578H213.629C213.863 55.2578 213.98 55.1771 213.98 55.0156C213.98 54.8672 213.863 54.793 213.629 54.793H211.69C210.886 54.793 210.29 54.6432 209.9 54.3437C209.513 54.0417 209.319 53.5586 209.319 52.8945C209.319 52.2305 209.513 51.7487 209.9 51.4492C210.29 51.1497 210.886 51 211.69 51Z" fill="#DEB018"/>
        </svg>

    </div>

    );
}

export default Logo;